import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, fetchSignInMethodsForEmail, signOut } from 'firebase/auth'
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import dayjs from 'dayjs'

const firebaseApp = initializeApp({
  apiKey: "AIzaSyDk93wPbVFCK7Srjhc1dGM6ZS0NWCKjvmw",
  authDomain: "homepagelp.firebaseapp.com",
  projectId: "homepagelp",
  storageBucket: "homepagelp.appspot.com",
  messagingSenderId: "494662465260",
  appId: "1:494662465260:web:67af115f290249802717c9"
})

export const app = firebaseApp;

// ドメインがlocalhostの場合は、デバッグトークンを有効にする
// localhostかどうかをチェック
if (location.hostname === "localhost"){
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}

const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6LfYbqUfAAAAAKdMGL8GlW1iYcxlv_D6RXEP6kdf'),
  isTokenAutoRefreshEnabled: true
});

export const token = appCheck;
const auth = getAuth();

const firestore = getFirestore(firebaseApp);
import {doc,getDoc,setDoc,getDocs,addDoc,deleteDoc,collection,updateDoc,query,where} from 'firebase/firestore';

export async function getUid(mail,pass){
  console.log('uid取得');
  return await signInWithEmailAndPassword(auth, mail, pass).then( userCredential => {
     console.log('ログイン成功',userCredential.user.uid)
     return userCredential.user.uid
  })
}

export async function userLogin(mail,pass){
  console.log('ログイン処理');
  return await signInWithEmailAndPassword(auth, mail, pass).then( userCredential => {
     console.log('ログイン成功',userCredential)
     return userCredential;
  })
}

export async function userLogout(){
  console.log('ログアウト処理');
  return await signOut(auth).then( () => {
     console.log('ログアウト成功')
     return false;
  })
}

export async function loginCheck(){
  console.log('ログインチェック',auth,auth.currentUser)
  return auth.currentUser;
}

export async function LoadReserve(uid){
  console.log('LoadReserve',uid);
  const date = dayjs().toDate();
  console.log('date',date);
  const reserve = await getDocs(query(collection(firestore, 'customer', uid,'reserve'),where('available' ,"==",false),where('temp',"==",false),where('start',">=",date)));
  console.log('reserve',reserve)

  let getDatas = [];
  reserve.forEach((doc) => {
    console.log(doc);
    let event = doc.data();
    console.log('event',event)
    event.id = doc.id;
    event.end = dayjs.unix(event.end.seconds).format('YYYY-MM-DD HH:mm')
    event.start = dayjs.unix(event.start.seconds).format('YYYY-MM-DD HH:mm')
    // event.start = dayjs(event.start).format('YYYY-MM-DD HH:mm');
    // event.end = dayjs(event.end).format('YYYY-MM-DD HH:mm');
    console.log('時刻チェック',event)
    let time = dayjs(event.start).format('HH時mm分～')+dayjs(event.end).format('HH時mm分');
    event.name = time;
    event.date = dayjs(event.start).format('M月D日')
    getDatas.push(event)
  });
  console.log('getdatas',getDatas)
  return getDatas;
}

export function AddReserveTemp(reserveSet,event){ //予約するボタンを押した時に、Tempにメールアドレスを追加して、予約枠を1つ増やす
  console.log('AddReserveTemp');
  try{
    const reserve = doc(firestore,'customer',reserveSet.uid,'reserve',event.id);
    updateDoc(reserve,{
      'temp': true,
    })
    return true;
  }catch(e){
    console.log('AddReserveTempでエラー',e)
  }

}

export function SubReserveTemp(reserveSet){
  console.log('SubReserveTemp');
  try {
    console.log('reserveSet.id',reserveSet.uid,reserveSet.event.id)
    const reserve = doc(firestore,'customer',reserveSet.uid,'reserve',reserveSet.event.id);
    console.log('reserve',reserve);
    updateDoc(reserve,{
      'temp':false,
    })
    return true;
  }catch(e){
    console.log('SubReserveTempでエラー',e)
  }
}

export function AddReserve(reserveSet){ //予約するボタンを押した時に、Tempにメールアドレスを追加して、予約枠を1つ増やす
  console.log('AddReserve');
  try{
    const reserve = doc(firestore,'customer',reserveSet.uid,'reserve',reserveSet.event.id);
    updateDoc(reserve,{
      'available': true,
    })
    return true;
  }catch(e){
    console.log('AddReserveでエラー',e)
  }

}

export function SubReserve(reserveSet){ //予約するボタンを押した時に、Tempにメールアドレスを追加して、予約枠を1つ増やす
  console.log('SubReserve',reserveSet)
  try{
    console.log('event.id',reserveSet.event.id)
    const reserve = doc(firestore,'customer',reserveSet.uid,'reserve',reserveSet.event.id);
    updateDoc(reserve,{
      'available': false,
    })
    return true;
  }catch(e){
    console.log('SubReserveでエラー',e)
  }

}

export async function checkTemp(reserveSet,event){
  const reserve = await getDocs(collection(firestore,'customer',reserveSet.uid,'reserve'));
  console.log('reserve',reserve,event)
  const result = reserve.docs.find(doc => doc.id === event.id).data();
  console.log('checkTemp result',result);
  return result.temp;
}

export async function UnReserve(reserveSet){
  // console.log('event.id',event.id)
  const reserve = await getDocs(collection(firestore,'customer',reserveSet.uid,'reserve'));
  console.log('reserve',reserve)
  return reserve.docs.find(doc => reserveSet.id === doc.id);
}

export async function addReserveDay(uid,event){
  console.log('addReserveDay',uid,event)
  await addDoc(collection(firestore,'customer',uid,'reserve'),event);
  return true;
}

// export async function addAllReserveDay(uid,event){
//   console.log('addAllReserveDay',uid,event)
//   await addDocs
// }

export async function exchangeReserve(uid,event){
  console.log('exchangeReserve');
  const reserve = doc(firestore,'customer',uid,'reserve',event.id);
    updateDoc(reserve,{
      'start': event.start,
      'end': event.end,
      'items': event.items,
    })
  return true;
}

export async function deleteReserve(uid,event){
  console.log('event',event)
  let reserveId = "";
  if (!event.id){
    reserveId = event;
  } else {
    reserveId = event.id;
  }
  const reserve = doc(firestore,'customer',uid,'reserve',reserveId);
  deleteDoc (reserve);
  return true;
}

export async function duplicateMailCheck(register){
  const check = await fetchSignInMethodsForEmail(auth,register.mail)
  console.log('check',check)
  return check;
}

export async function registerUser(register){
  return await createUserWithEmailAndPassword(auth,register.mail,register.pass);
}

//ZOOM APIなどの設定情報の作成、保存、変更など

export function SaveApiData(uid,data){
  console.log('SaveApiData');
  try{
    const reserve = doc(firestore,'customer',uid);
    updateDoc(reserve,data)
    return true;
  }catch(e){
    console.log('SaveApiData',e)
  }
}

export function createApiData(uid,data){
  console.log('createApiData');
  try{
    const reserve = doc(firestore,'customer',uid);
    setDoc(reserve,data)
    return true;
  }catch(e){
    console.log('createApiData',e)
  }
}

export async function LoadApiData(uid){
  console.log('LoadApiData');
  try{
    const reserve = doc(firestore,'customer',uid);
    const keys = await getDoc(reserve)
    return keys;
  }catch(e){
    console.log('LoadApiData',e)
  }

}

export async function saveSecretApiData(uid,data){
  try{
    let reserve = null;
    if(data.docId){
      reserve = doc(firestore, 'customer', uid, 'secret', data.docId); 
      await setDoc(reserve,data ,{merge:true})
    } else {
      console.log('新規作成')
      reserve = collection(firestore, 'customer', uid, 'secret'); 
      await addDoc(reserve,data ,{merge:true})
    }
    
    // const result = await setDoc(reserve,data ,{merge:true})
    // console.log('saveSecretApiData',result)
    return true;
  }catch(e){
    console.log('saveSecretApiData',e)

  }
}

export async function loadSecretApiData(uid){
  try{
    const reserve = collection(firestore,'customer',uid,'secret');
    const keys = await getDocs(reserve)
    for(const doc of keys.docs){
      return doc;
    }
    // return keys;
  }
  catch(e){
    console.log('loadSecretApiData',e)
  }
}

//予約者の情報の保存など

export async function saveReserverData(reserveSet){
  console.log('saveReserverData',reserveSet);
  try{
    const date = dayjs().toDate();
    const reserve = await getDocs(query(collection(firestore, 'customer', reserveSet.uid,'reserve'),where('start',">=",date)));
    console.log('reserve',reserve)
    const reserverDateId = reserve.docs.find(doc => reserveSet.event.id === doc.id);
    console.log('reserverDateId',reserverDateId)
    const reserveUpdate = doc(firestore,'customer',reserveSet.uid,'reserve',reserverDateId.id);
    if(!reserveSet.paymentID){
      reserveSet.paymentID = null;
    }
    updateDoc(reserveUpdate,{
      'name': reserveSet.name,
      'reserverMailAddress': reserveSet.reserverMailAddress,
      'reserveId': reserveSet.event.id,
      'paymentId': reserveSet.paymentID,
      'zoomURL': reserveSet.zoomUrl,
    })
  return true;
  }catch(e){
    console.log("saveReserverData",e);
  }
}

export async function loadReserveList(uid){
  console.log('loadReserveList');
  try{
    const reserve = await getDocs(query(collection(firestore,'customer',uid,'reserve'),where('available' ,"==",true)))
    console.log('reserve',reserve)
    const reserver = reserve.docs.map(doc => {
      return doc.data();
    });
    return reserver;
  }catch(e){
    console.log("loadReserveList",e)
  }
}

// 商品保存
export async function saveItems(uid,items){
  console.log('saveItems',items);
  // itemsをforeachで実行して、それぞれの商品を保存する
  // 保存する際に、itemIdが空の場合は、新規作成、itemIdがある場合は、更新する
  // 保存するパスは、customer/uid/items/itemId
  try{
    items.forEach(async item => {
      console.log('item',item)
      if(item.itemId === ""){
        console.log('新規作成')
        await addDoc(collection(firestore,'customer',uid,'items'),item);
      } else {
        console.log('更新')
        const itemUpdate = doc(firestore,'customer',uid,'items',item.itemId);
        await updateDoc(itemUpdate,item);
      }
    }
    )
    return true;
  }catch(e){
    console.log('saveItems',e)
    return false;
  }
}

export async function loadItems(uid){
  console.log('loadItems');
  try{
    const items = await getDocs(collection(firestore,'customer',uid,'items'));
    const itemData = items.docs.map(doc => {
      // 各docのidを取得して、itemに入れる
      const item = doc.data();
      item.itemId = doc.id;
      return item;
    })
    return itemData;
  }catch(e){
    console.log('loadItems',e)
  }
}

export async function deleteItem(uid,itemId){
  console.log('deleteItem');
  try{
    const item = doc(firestore,'customer',uid,'items',itemId);
    // deleteというキー名を作り、trueを入れる
    await updateDoc(item,{
      'delete': true,
    })
    return true;
  }catch(e){
    console.log('deleteItem',e)
  }
}

export async function createSquareToken(uid){
  console.log('createSquareToken');
  try{
    const reserve = doc(firestore,'customer',uid);
    const keys = await getDoc(reserve)
    const squareApiKeys = keys.data();
    let square = null;
    console.log('keys',squareApiKeys,window.Square)
    if(squareApiKeys.SquareTestMode){
      console.log('テストモード')
    square = await window.Square.payments(squareApiKeys.squareAppIdTest, squareApiKeys.squareLocationIdTest);
    }else {
      square = await window.Square.payments(squareApiKeys.squareAppId, squareApiKeys.squareLocationId);
    }
    return square;
  }catch(e){
    console.log('createSquareToken',e)
  } 
}