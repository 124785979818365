<template>
  <component
    :is="compList[compListNumber]"
    @next-comp="nextComp"
    @back-comp="backComp"
    @send-confirmation="sendConfirmation"
    @back-firstcomp="backFirstComp"
    :reserveSet="reserveSet"
    :reserveList="reserveList"
    :reservationList="reservationList"
  >
  </component>
</template>

<script>
// @ is an alias to /src
import reserved from '@/components/reserved.vue'
import confirmation from '@/components/confirmation.vue'
import thankyou from '@/components/thankyou.vue'
import settings from '@/components/settings.vue'
import unreserve from '../views/unreserve.vue'
import { LoadReserve } from '@/modules/OperationFirebase.js';
// import dayjs from 'dayjs'

export default {
  components: {
    reserved,
    confirmation,
    thankyou,
    settings,
    unreserve,
  },
  data(){
    return {
      compList:[
        reserved,
        confirmation,
        thankyou,
        settings,
        unreserve
      ],
      compListNumber:0,
      reserveList:[],
      reservationList:[],
      reserveSet:{
        uid:'',
        reserveDate:'',
        name:'',
        reserverMailAddress:'',
      },
    }
  },
  methods:{
    nextComp(){
      console.log('次のコンポーネントへ移動')
      if(this.compListNumber < 2){
        this.compListNumber++;
      }
    },
    backComp(){
      console.log('前のコンポーネントへ移動')
      // if(getDatas){
      //   console.log('予約リスト再読み込み');
      //   this.getReserveList();
      // }
      if(this.compListNumber > 0){
        this.compListNumber--;
      }
    },
    backFirstComp(){
      console.log('backFirstComp')
      this.getReserveList();
      this.compListNumber = 0;
    },
    async getReserveList(){
      this.reserveList = [];
      this.reserveSet.uid = this.$route.query.uid;
      console.log('getReserveList',this.reserveSet.uid)
      const getDatas = await LoadReserve(this.reserveSet.uid);
      getDatas.forEach(data => {
        this.reserveList.push(data);
      })
      console.log('this.reserveList',this.reserveList)
    },
    sendConfirmation(event){
      this.reserveSet = event;
    },
    checkTempReserveSet(){
      console.log('checkTempReserveSet 開始')
      if(localStorage.getItem('reserveSet')){
        Object.assign(this.reserveSet,JSON.parse(localStorage.getItem('reserveSet')));
        if(this.reserveSet.event){
          console.log('aathis.reserveSet',this.reserveSet,JSON.parse(localStorage.getItem('reserveSet')).uid)
          this.nextComp();
        }
        } else {
          this.getReserveList();
      }
    },
    mailQueryGet(){
      if(this.$route.params.reserveset){
        const reserveset = this.$route.params.reserveset.split('**');
        this.reserveSet.name = reserveset[0];
        this.reserveSet.reserverMailAddress = reserveset[1];
      }
    },
  },
  created(){
    this.checkTempReserveSet();
    if(this.$route.query.path === 'settings'){
      this.compListNumber = 3;
      console.log('this.compListNumber',this.compListNumber)
    } else if(this.$route.query.path === "unreserve"){
      this.compListNumber = 4;
    } else if(this.$route.query.path === "register")
      this.$router.push({
        path:"register",
      })
  },
  mounted(){
    console.log('mounted');
  }
}
</script>
