<template>
  <v-container>
    <v-row justify="center">
      <v-col lg="4">
        <v-text-field label="お名前" v-model="keys.name">
          <template v-slot:prepend>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">
                  mdi-help-circle-outline
                </v-icon>
              </template>
              メール本文に表示される名前です。
            </v-tooltip>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col lg="4">
        <v-text-field label="返信先メールアドレス" v-model="keys.replyMailAddress">
          <template v-slot:prepend>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">
                  mdi-help-circle-outline
                </v-icon>
              </template>
              返信先のメールアドレスです。
            </v-tooltip>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col lg="4">
        <v-text-field v-model="testMailAddress" label="テストメールの送信先"></v-text-field>
        <v-btn @click="sendTestMail" :disabled="clientResponse">
          テストメールを送信する
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col lg="4">
        <v-text-field label="予約名" v-model="keys.subject">
          <template v-slot:prepend>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">
                  mdi-help-circle-outline
                </v-icon>
              </template>
              予約完了時のメールの件名です。
            </v-tooltip>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col lg="4">
        <v-textarea label="メール本文の前半部" v-model="keys.mainTextBefore">
          <template v-slot:prepend>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">
                  mdi-help-circle-outline
                </v-icon>
              </template>
              メール本文のお客様名と日程の間の部分です。詳しいレイアウトはテストメールを送信して確認をお願いします。
            </v-tooltip>
          </template>
        </v-textarea>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col lg="4">
        <v-textarea label="メール本文の後半部" v-model="keys.mainTextAfter">
          <template v-slot:prepend>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">
                  mdi-help-circle-outline
                </v-icon>
              </template>
              メール本文の日程と署名の間の部分です。詳しいレイアウトはテストメールを送信して確認をお願いします。
            </v-tooltip>
          </template>
        </v-textarea>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col lg="4">
        <v-textarea label="メール署名" v-model="keys.signature">
          <template v-slot:prepend>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">
                  mdi-help-circle-outline
                </v-icon>
              </template>
              メール下部に表示される署名です。
            </v-tooltip>
          </template>
        </v-textarea>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col lg="1">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              mdi-help-circle-outline
            </v-icon>
          </template>
          予約申し込み画面に表示される、予約可能日の色です。
        </v-tooltip>
        予約カラー
      </v-col>
      <v-col lg="3">
        <v-color-picker label="予約カラー" v-model="keys.color">
        </v-color-picker>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col lg="4">
        <v-text-field label="ZOOM accountId" v-model="secretKeys.accountId">
          <template v-slot:prepend>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">
                  mdi-help-circle-outline
                </v-icon>
              </template>
              ZOOMのDeveloperで登録したaccountIdを入力してください。
            </v-tooltip>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col lg="4">
        <v-text-field label="ZOOM clientId" v-model="secretKeys.clientId">
          <template v-slot:prepend>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">
                  mdi-help-circle-outline
                </v-icon>
              </template>
              ZOOMのDeveloperで登録したclientIdを入力してください。
            </v-tooltip>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col lg="4">
        <v-text-field label="ZOOM clientSecret" v-model="secretKeys.clientSecret">
          <template v-slot:prepend>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">
                  mdi-help-circle-outline
                </v-icon>
              </template>
              ZOOMのDeveloperで登録したclientSecretを入力してください。
            </v-tooltip>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col lg="4">
        <v-text-field label="Slack Webhook" v-model="keys.slackWebhook">
          <template v-slot:prepend>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">
                  mdi-help-circle-outline
                </v-icon>
              </template>
              Slackで発行したWebhookを入力すると、Slackで指定したチャンネルへ、予約確定時にメッセージが送信されるようになります。
            </v-tooltip>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col lg="4">
        <v-text-field label="予約料金" v-model="keys.creditAmount">
          <template v-slot:prepend>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">
                  mdi-help-circle-outline
                </v-icon>
              </template>
              予約を取る際に料金が掛かる場合は、こちらに入力してください。
            </v-tooltip>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col lg="4">
        <v-switch v-model="keys.SquarePayment" label="Squareによるクレジット決済を有効にする（下記3項目が埋まっていないと決済が出来ずエラーになります）"></v-switch>
      </v-col>
    </v-row>
    <template v-if="testmode">
      <v-row justify="center">

        <v-col lg="4">
          <v-switch v-model="secretKeys.SquareTestMode" label="Squareのテストモードを有効にする"></v-switch>
        </v-col>

      </v-row>
      <v-row justify="center">

        <v-col lg="4">
          <v-text-field label="Square LocationId テスト環境用" v-model="keys.squareLocationIdTest">
            <template v-slot:prepend>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                SquareのDeveloperで発行したlocationIDのテスト用キーです。
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>

      </v-row>
      <v-row justify="center">

        <v-col lg="4">
          <v-text-field label="Square AppID テスト環境用" v-model="keys.squareAppIdTest">
            <template v-slot:prepend>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                SquareのDeveloperで発行したAppIDのテスト用キーです。
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>

      </v-row>
      <v-row justify="center">

        <v-col lg="4">
          <v-text-field label="Square Access token テスト環境用" v-model="secretKeys.squareAccessTokenTest">
            <template v-slot:prepend>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                SquareのDeveloperで発行したアクセストークンです。
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>

      </v-row>
    </template>
    <v-row justify="center">
      <v-col lg="4">
        <v-text-field label="Square LocationID" v-model="keys.squareLocationId">
          <template v-slot:prepend>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">
                  mdi-help-circle-outline
                </v-icon>
              </template>
              SquareのDeveloperで発行したロケーションIDです。
            </v-tooltip>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col lg="4">
        <v-text-field label="Square AppID" v-model="keys.squareAppId">
          <template v-slot:prepend>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">
                  mdi-help-circle-outline
                </v-icon>
              </template>
              SquareのDeveloperで発行したAppIDです。
            </v-tooltip>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col lg="4">
        <v-text-field label="Square Access token" v-model="secretKeys.squareAccessToken">
          <template v-slot:prepend>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">
                  mdi-help-circle-outline
                </v-icon>
              </template>
              SquareのDeveloperで発行したアクセストークンです。
            </v-tooltip>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col>
        <v-btn @click="save">
          保存する
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { SaveApiData, LoadApiData,loadSecretApiData,saveSecretApiData } from '@/modules/OperationFirebase.js';
import { sendMailTemplate } from '@/modules/CloudFunctions.js';

export default {
  props:['uid'],
  data(){
    return {
      testmode: true,
      apikeys:[
        {
          title:"送信元メールアドレス",
          model:"",
        },
        {
          title:"ZOOM ID（メールアドレス）",
          model:"",
        },
        {
          title:"ZOOM シークレットキー",
          model:"",
        },
        {
          title:"ZOOM API Key",
          model:"",
        },
        {
          title:"Slack Webhook",
          model:"",
        }
      ],
      keys:{
        name:"",
        mailAddress:"",
        replyMailAddress:"",
        subject:"",
        mainTextBefore:"",
        mainTextAfter: "",
        color:"",
        slackWebhook:"",
        creditAmount: "",
        squareLocationIdTest: "",
        squareAppIdTest:"",
        squareLocationId:"",
        squareAppId: "",
        
      },
      secretKeys:{
        SquareTestMode: false,
        docId:"",
        accountId:"",
        clientId:"",
        clientSecret:"",
        SquarePayment:"",
        squareAccessTokenTest:"",
        squareAccessToken: "",
      },
      testMailAddress:"",
      clientResponse:false,
    }
  },
  methods:{
    async save(){
      const result = confirm('保存しますか？')
      if(result){
        console.log('保存処理')
        await SaveApiData(this.uid,this.keys)
        await saveSecretApiData(this.uid,this.secretKeys)
      }
    },
    async loadDefaultData(){
      const loadKeys = await LoadApiData(this.uid);
      const loadSecretKeys = await loadSecretApiData(this.uid);
      console.log('loadSecretKeys',loadSecretKeys);
      Object.assign(this.keys, loadKeys.data());
      Object.assign(this.secretKeys, loadSecretKeys.data())
      this.secretKeys.docId = loadSecretKeys.id;
      console.log('docid',this.secretKeys.docId)
      // console.log('keys',this.keys);
    },
    async sendTestMail(){
      const msg = {
        testFlag:true,
        templateId: 'd-957a2dab508e4d6fbf2bdc0de8d1266b',
        to: this.testMailAddress,
        from: "reservesystem@infom.jp",
        replyTo: this.keys.replyMailAddress,
        dynamic_template_data: {
          clientName: "テスト名",
          subject: "メール送信テスト",
          mainTextBefore: this.keys.mainTextBefore,
          mainTextAfter: this.keys.mainTextAfter,
          signature: this.keys.signature,
          reserveDate: "2022年7月19日10時00分～11時00分",
          zoomUrl:"#",
          zoomUrlLabel: "zoomURLがここに入ります",
        }
      }
      console.log('msg',msg);
      this.clientResponse = true;
      this.clientResponse = await sendMailTemplate(msg);
      setTimeout(this.clientResponse = false, 5000)
    }
  },
  mounted(){
    this.loadDefaultData();
  }
}
</script>