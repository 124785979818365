<template>
  <v-content style="padding-left:0px;">
    <v-sheet tile height="54" class="d-flex">
      <v-container>
        <v-row justify="center" align-content="center">
          <v-col align="right">
            <v-btn icon @click="prevMonth()">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            {{date}}
            <v-btn icon @click="nextMonth()">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-col>
          <v-col>
            <v-dialog v-model="show" width="500px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-on="on" v-bind="attrs" @click="show = !show">
                  <v-icon>
                    mdi-help-circle-outline
                  </v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title>使い方</v-card-title>
                <v-card-text>
                  １．予約の作成方法<br>
                  予約を入れたい日付をクリックし、時間帯を入力してください。時間は24時間表記です。<br><br>
                  ２．予約の複製方法<br>
                  複製したい予約時間帯を右クリックし、追加したい日の日付か空いているスペースで右クリックして貼り付けします。<br><br>
                  ３．予約の削除方法<br>
                  削除したい予約時間帯を右クリックし、削除を選択します。元に戻す事は出来ないので注意してください。<br><br>
                  ４．その他、注意事項<br>
                  現在の日時より古い予約可能時間帯と、すでに予約が入った予約可能時間帯は表示されません。<br>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <v-sheet height="600">
      <v-calendar locale="ja-JP" :day-format="timestamp => new Date(timestamp.date).getDate()"
        :month-format="timestamp => (new Date(timestamp.date).getMonth() + 1) + ' /'" ref="calendar" v-model="focus"
        :events="events" :start="startDate" :type="type" event-more-text="続きを表示する" :event-color="color"
        @change="getEvents" @click:more="viewDay" @click:date="addEvent" @click:event="showEvent"
        @contextmenu:day="pasteRightClick" @contextmenu:event="copyRightClick">
      </v-calendar>
      <v-dialog v-model="viewDayDialog" width="600px">
        <v-calendar locale="ja-JP" ref="daycalendar" :type="dayType" v-model="dayFocus" :events="dayEvents"
          first-time="09-00" :event-color="color" @change="getDayEvents" @click:date="addEvent" @click:event="showEvent"
          @contextmenu:event="copyRightClick"></v-calendar>
      </v-dialog>
      <v-dialog width="200px" v-model="selectedOpen">
        <v-card width="200px" flat>
          <v-card-title>
            <v-toolbar-title>
              内容変更
            </v-toolbar-title>
          </v-card-title>
          <v-card-text>
            <v-form>
              <v-row>
                <v-col><v-text-field v-model="reserveSet.starthour" label="開始時間" maxlength="2" suffix="時" required class="d-span" /></v-col>
                <v-col><v-text-field v-model="reserveSet.startminute" label="開始分" maxlength="2" suffix="分" required /></v-col>
              </v-row>
              <v-row>
                <v-col><v-text-field v-model="reserveSet.endhour" label="終了時間" maxlength="2" suffix="時" required /></v-col>
                <v-col><v-text-field v-model="reserveSet.endminute" label="終了分" maxlength="2" suffix="分" required /></v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-checkbox v-for="(item,index) in reserveSet.items" :key="index" v-model="item.use"
                    :label="item.title"></v-checkbox>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="exchange">
              変更する
            </v-btn>
            <v-btn text @click="deleteReserve">
              削除する
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialog" width="320px">
        <v-card>
          <v-card-title>
            新規予約日追加
          </v-card-title>
          <v-card-text>
            <v-form>
              <v-row>
                <v-col><v-text-field v-model="reserveSet.month" maxlength="2" suffix="月"></v-text-field></v-col>
                <v-col><v-text-field v-model="reserveSet.day" maxlength="2" suffix="日"></v-text-field></v-col>
              </v-row>
              <v-row>
                <v-col><v-text-field v-model="reserveSet.starthour" label="開始時間" maxlength="2" suffix="時" required /></v-col>
                <v-col><v-text-field v-model="reserveSet.startminute" label="開始分" maxlength="2" suffix="分" required /></v-col>
              </v-row>
              <v-row>
                <v-col><v-text-field v-model="reserveSet.endhour" label="終了時間" maxlength="2" suffix="時" required /></v-col>
                <v-col><v-text-field v-model="reserveSet.endminute" label="終了分" maxlength="2" suffix="分" required /></v-col>
              </v-row>
              <v-row>
                <v-col>
                  <!-- チェックボックス。itemsの数だけv-forで表示 -->
                  <!-- 「予約品目」と表示させる -->
                  予約品目
                  <v-checkbox v-for="(item,index) in reserveSet.items" :key="index" v-model="item.use"
                    :label="item.title"></v-checkbox>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="addReserveDate">
              予約を追加する
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-menu v-model="copyRightClickOpen" :position-x="x" :position-y="y" absolute offset-y>
        <v-list>
          <v-list-item @click="copyEvent">
            コピー
          </v-list-item>
          <v-list-item @click="deleteReserve">
            削除
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu v-model="pasteRightClickOpen" :position-x="x" :position-y="y" absolute offset-y>
        <v-list>
          <v-list-item @click="copyAllEvent">
            全てコピー
          </v-list-item>
          <v-list-item @click="pasteEvent">
            貼り付け
          </v-list-item>
        </v-list>
      </v-menu>
    </v-sheet>
  </v-content>
</template>

<script>
import {addReserveDay,LoadReserve,exchangeReserve,deleteReserve,LoadApiData,loadItems} from '@/modules/OperationFirebase.js';
import dayjs from 'dayjs';

export default {
  props:['uid'],
  data(){
    return {
      focus:'',
      dayFocus:'',
      type:'month',
      dayType:'day',
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      dayEvents:[],
      date: null,
      reserveSet:{
        items:[]
      },
      reserveList:[],
      dialog:false,
      viewDayDialog:false,
      copy:null,
      copyAll:null,
      copyRightClickOpen:false,
      pasteRightClickOpen:false,
      copytemp:{},
      copytempAll:[],
      pastetemp:{},
      pastetempAll:[],
      x:0,
      y:0,
      color:"",
      mail:"",
      pass:"",
      show:false,
    }
  },
  methods:{
    getEvents(){
      this.events = this.reserveList;
      console.log('this.events',this.events);
    },
    getDayEvents(){
      const day = dayjs(this.dayFocus).format("M月D日");
      console.log('day',day);
      console.log('this.selectedElement',this.selectedElement)
      this.dayEvents = this.reserveList.map(event => {
        if(event.date === day){
          console.log(event)
          event.start = dayjs(event.start).format('YYYY-MM-DD HH:mm');
          event.end = dayjs(event.end).format('YYYY-MM-DD HH:mm');
          return event;
        }
      })
      this.dayEvents = this.dayEvents.filter( n => n != undefined)
      console.log('dayEvnets',this.dayEvents)
    },
    viewDay ({ date }) {
      console.log('viewDay',date)
      this.viewDayDialog = true;
      this.dayFocus = date;
    },
    showEvent({ nativeEvent,event }){ //カレンダーのイベント名をクリックしたときの関数
      // console.log('クリックしました');
        console.log('nativeEvent',nativeEvent,'event',event)
        const open = () => {
          this.reserveSet.starthour = dayjs(event.start).format('HH');
          this.reserveSet.startminute = dayjs(event.start).format('mm');
          this.reserveSet.endhour = dayjs(event.end).format('HH');
          this.reserveSet.endminute = dayjs(event.end).format('mm');
          this.reserveSet.items = event.items;
          this.reserveSet.id = event.id;
          this.selectedEvent = event
          this.selectedElement = nativeEvent.target
          this.selectedOpen = !this.selectedOpen;
          requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
        }
        if (this.selectedOpen) {
        this.selectedOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    exchange(){
        console.log('時間変更');
        let setData = this.reserveList.find(value => value.id === this.reserveSet.id);
        console.log('setData',setData);
        setData.start = dayjs(new Date(setData.start)).hour(this.reserveSet.starthour).minute(this.reserveSet.startminute).toDate();
        console.log('setData.start',setData.start);
        setData.end = dayjs(new Date(setData.end)).hour(this.reserveSet.endhour).minute(this.reserveSet.endminute).toDate();
        console.log('setData.end',setData.end);
        setData.items = this.reserveSet.items;
        const result = exchangeReserve(this.uid,setData);
        if(result){
          // alert('変更しました')
          this.reserveList = [];
          this.getReserveList();
          this.getEvents();
          this.selectedOpen = false
        }
    },
    deleteReserve(){
      console.log('予定削除')
      const res = confirm('予定を削除して良いですか？（戻す事は出来ません）')
      if(res){
        let setData = this.reserveList.find(value => value.id === this.reserveSet.id);
        const result = deleteReserve(this.uid,setData);
        if(result){
          alert('削除しました');
          this.resetObject();
          this.getReserveList();
          this.getEvents();
          this.selectedOpen = false
        }
      }
    },
    addEvent({ year,month,day }){
      console.log('スケジュール追加')
      this.reserveSet.year = year;
      this.reserveSet.month = month;
      this.reserveSet.day = day;
      this.dialog = true;
    },
    async addReserveDate(){
      console.log('予約新規追加')
      console.log('reserveSet',this.reserveSet);
      let setData = {
        temp: false,
        available: false,
      };
      const day = dayjs(this.reserveSet.year+"-"+this.reserveSet.month+"-"+this.reserveSet.day);
      setData.start = dayjs(day).hour(this.reserveSet.starthour).minute(this.reserveSet.startminute).toDate();
      setData.end = dayjs(day).hour(this.reserveSet.endhour).minute(this.reserveSet.endminute).toDate();
      setData.items = this.reserveSet.items;
      console.log('setData',setData);
      const result = await addReserveDay(this.uid,setData);
      console.log(result);
      if(result){
        this.resetObject();
        this.getReserveList();
        this.getEvents();
        this.dialog = false;
      }
    },
    async getReserveList(){
      console.log('this.uid',this.uid);
      const getDatas = await LoadReserve(this.uid);
      this.userLogin = true;
      console.log('this.userLogin',this.userLogin)
      getDatas.forEach(data => {
        // data.start = dayjs(data.start).format('YYYY-MM-DD HH:mm');
        // data.end = dayjs(data.end).format('YYYY-MM-DD HH:mm');
        this.reserveList.push(data);
      })
      console.log('this.reserveList',this.reserveList)
    },
    copyRightClick({event,nativeEvent}){
      console.log('copyRightClick',event,nativeEvent)
      this.x = nativeEvent.clientX
      this.y = nativeEvent.clientY
      this.copyRightClickOpen = true;
      this.copytemp = event;
      this.reserveSet.id = event.id;
    },
    copyEvent(){
      this.copy = this.copytemp;
      this.copyAll = null;
      console.log('コピーしました')
    },
    pasteRightClick({date,nativeEvent}){
      if(this.copyRightClickOpen){return}
      console.log('pasteRightEvent',date,nativeEvent)
      this.x = nativeEvent.clientX
      this.y = nativeEvent.clientY
      this.pasteRightClickOpen = true;
      this.pastetemp = date;
    },
    pasteEvent(){
      console.log('pasteEvent',this.copy,this.copyAll)
      if(this.copy){
        this.pasteEventDo();
      } else if (this.copyAll){
        this.pasteAllEventDo();
      } else {
        alert('予約がコピーされていません')
      }
    },
    async pasteEventDo(){
      console.log('pastetemp', this.pastetemp, this.copy)
      const exchangeDate = this.exchangeReserveDate(this.pastetemp, this.copy);
      this.copy.start = exchangeDate.start;
      this.copy.end = exchangeDate.end;
      this.copy.date = exchangeDate.date;
      delete this.copy.id;
      const result = await addReserveDay(this.uid, this.copy);
      console.log(result);
      if (result) {
        this.resetObject();
        this.getReserveList();
        this.getEvents();
        this.dialog = false;
        console.log('ペーストしました')
      }
    },
    async pasteAllEventDo() {
      console.log('pasteAllEvent', this.pastetemp, this.copyAll);
      await this.copyAll.forEach(async value => {
        const exchangeDate = this.exchangeReserveDate(this.pastetemp, value);
        console.log('pasteAll value', value);
        value.start = exchangeDate.start;
        value.end = exchangeDate.end;
        value.date = exchangeDate.date;
        delete value.id;
        console.log(value);
        await addReserveDay(this.uid, value);
      })
      console.log('reserveList',this.reserveList);
        this.resetObject();
        this.getReserveList();
        this.getEvents();
        this.dialog = false;
        console.log('ペーストしました')
    },
    copyAllEvent(){
      console.log('copyAllEvent', this.reserveList, this.pastetemp);
      const cutDateString = 10;
      this.copytempAll = Object.assign(this.copytempAll,this.reserveList.filter(value => value.start.substr(0, cutDateString) === this.pastetemp));
      this.copyAll = this.copytempAll;
      console.log('copyAll', this.copyAll);
      this.copy = null;
    },
    exchangeReserveDate(pasteDate,copydate){ //予約データの日時をdayjsを使ってフォーマットを変換する
      const year = dayjs(pasteDate).year();
      const month = dayjs(pasteDate).month();
      const day = dayjs(pasteDate).date();
      const date = month+1+"月"+day+"日";

      console.log(year, month, day);
      const copyDate = {};
      copyDate.start = dayjs(copydate.start).year(year).month(month).date(day).toDate();
      copyDate.end = dayjs(copydate.end).year(year).month(month).date(day).toDate();
      copyDate.date = date;
      console.log(copyDate);
      return copyDate;
    },
    setDate(){
      let temp = this.$refs.calendar.title;
      console.log(typeof(temp),temp)
      temp = temp.split(' ');
      console.log('temp',temp)
      this.date = temp[1]+'年'+temp[0];
      console.log(this.date);
    },
    async nextMonth(){
      await this.$refs.calendar.next();
      this.setDate();
    },async prevMonth(){
      await this.$refs.calendar.prev();
      this.setDate();
    },
    resetObject(){
      this.reserveList = [];
      this.reserveSet = {};
      this.copytemp = {};
      this.pastetemp = '';
    },
    async getColor(){
      const result = await LoadApiData(this.$route.query.uid);
      const keys = await result.data();
      console.log('color',keys.color)
      if(keys.color){
        console.log('color get',keys.color);
        this.color = keys.color.hex;
      } else {
        console.log('color notget',result);
        this.color = "#1976D2";
      }
    },
    async loadItemData(){
      const result = await loadItems(this.uid);
      result.forEach((item) => {
          if(!item.delete){
              this.reserveSet.items.push(item)
          }
      })
      console.log('items',this.reserveSet.items)
    }
  },
  computed:{
    startDate(){
      return dayjs().format('YYYY-MM-DD');
    },
  },
  mounted(){
    this.setDate();
    this.getReserveList();
    this.getColor();
    this.loadItemData();
  },
  created(){
    // this.logincheck();

  }
}
</script>