<template>
    <v-container>
        <!-- v-rowをitemsの数だけ表示 -->
        <v-row>
            <v-col>
                <v-btn @click="saveData">保存する</v-btn>
            </v-col>
        </v-row>
        <v-row v-for="(item, index) in items" :key="index">
            <!-- v-colで横幅を指定 -->
            <v-col cols="12" sm="6" md="4">
                <!-- v-cardでカードを作成 -->
                <v-card>
                    <!-- 商品名、価格を追加する。各項目はv-modelで入力できるように -->
                    <v-card-title>
                        <v-text-field v-model="item.title" label="コース名"></v-text-field>
                    </v-card-title>
                    <v-card-text>
                        <v-text-field v-model="item.price" label="税込価格"></v-text-field>
                    </v-card-text>
                    <!-- 商品を追加するaddItemのボタンを作る。追加するとitemsのオブジェクトが1つ増える。 -->
                    <v-card-actions>
                        <v-btn @click="addItem">商品を追加する</v-btn>
                        <v-btn v-if="items.length > 1" @click="subItem(index)">商品を削除する</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {saveItems,loadItems,deleteItem} from '@/modules/OperationFirebase.js';


export default{
    props:['uid'],
    data(){
        return {
            items:[],

        }
    },
    methods:{
        addItem(){
            this.items.push({
                title:'',
                price:'',
                itemId:'',
            })
        },
        subItem(index){
            // this.itemsの数をチェックして1未満の長さにしないようにする
            console.log('itemid',this.items[index].itemId)
            if(this.items.length < 1){
                // 削除できない旨のエラーメッセージを出す
                alert('商品を0に出来ません')
                return
            } else if(this.items.length > 1 && this.items[index].itemId){
                // 削除確認のアラートを出す。OKなら削除する
                const result = confirm('削除しますか？')
                if(result){
                    deleteItem(this.uid,this.items[index].itemId);
                    this.items.splice(index,1)
                }else{
                    return
                }
            } else if(this.items.length > 1){
                this.items.splice(index,1)
            }
        },
        async saveData(){
            // itemsを保存する処理を書く
            // 保存が完了したら、保存完了の旨のメッセージを出す
            const result = await saveItems(this.uid,this.items);
            if(result){
                alert('保存しました')
            }else{
                alert('保存に失敗しました')
            }
        },
        async loadData(){
            // itemsを読み込む処理を書く
            // 読み込みが完了したら、読み込み完了の旨のメッセージを出す
            const result = await loadItems(this.uid);
            if(!result){
                this.items.push({
                    title:'',
                    price:'',
                    itemId:'',
                })
            }
            // resultの各オブジェクトのうち、deleteがtrueのものを除外してthis.itemsに入れる
            result.forEach((item) => {
                if(!item.delete){
                    this.items.push(item)
                }
            })
            console.log('items',this.items)
            
        }
    },
    mounted(){
        this.loadData();
    }
}
</script>