<template>
    <!-- vuetifyで入力フォームを作る。メールアドレスとパスワードの2項目 -->
    <v-container fill-height>
    <v-row
      align-content="center"
    >
      <v-col
        align="center"
      >
        <v-card
          v-if="!success"
          width="600"
        >
          <v-card-title>
            新規登録をします
          </v-card-title>
          <v-card-text>
            <v-row>
                <v-col>
                    <v-text-field
                        v-model="mail"
                        :rules="[rules.required, rules.email]"
                        label="メールアドレス"
                        required
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
              <v-col
                align="right"
                align-self="center"
                cols="4"
              >
                パスワード
              </v-col>
              <v-col
                align="left"
                cols="6"
              >
                <v-text-field
                  v-model="pass1"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show1 ? 'text' : 'password'"
                  :rules="[rules.min]"
                  hint="6文字以上"
                  counter
                  @click:append="show1 = !show1"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                align="right"
                align-self="center"
                cols="4"
              >
                再入力
              </v-col>
              <v-col
                align="left"
                cols="6"
              >
                <v-text-field
                  v-model="pass2"
                  :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show2 ? 'text' : 'password'"
                  counter
                  :rules="[rules.min]"
                  hint="6文字以上"
                  @click:append="show2 = !show2"
                  @keydown="pushEnter"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="submit"
            >
              登録する
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card
          v-else
          width="600"
        >
          <v-card-title>
            アカウントの作成が完了致しました！
          </v-card-title>
          <v-card-text>
            登録したメールアドレス宛に、設定用のコードを送信致します。<br>予約ページを追加したいアドレスへ追加をお願い致します。
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { registerUser,createApiData } from "../modules/OperationFirebase";
// import { sendMailTemplate } from "../modules/CloudFunctions";

export default {
  data(){
    return {
      templateId:null,
      success:false,
      register:{
        mail:null,
        pass:null,
      },
      show1:false,
      show2:false,
        mail:null,
      pass1:null,
      pass2:null,
      reserveURL:"https://reserve.infom.jp/?uid=",
      reserveSettingURL:"https://reserve.infom.jp/?path=settings&uid=",
      rules:{
        min: v => v.length >= 6,
      }

    }
  },
  methods:{
    passConfirm(){
      if(this.pass1 === this.pass2){
        return true;
      } else {
        return false;
      }
    },
    submit(){
      const result = this.passConfirm();
      if(result){
        this.register.mail = this.mail;
        this.register.pass = this.pass1;
        console.log('this.register',this.register)
        registerUser(this.register).then(res => {
          console.log('res',res);
          console.log(res.user.uid);
          this.success = true;
        //   const msg = {
        //     to: this.register.mail,
        //     from: "info@infom.jp",
        //     // subject: "かんたん予約アプリのご登録について",
        //     // bcc: "info@infom.jp",
        //     templateId: "d-297dddcb6af44dc4ba760bed0c092323",
        //     dynamic_template_data: {
        //       name: this.register.name,
        //       reserveURL: this.reserveURL+res.user.uid,
        //       reserveSettingURL: this.reserveSettingURL+res.user.uid,
        //     },
        //   };
          // const msg = new SendMailMsg();
        //   sendMailTemplate(msg); //一旦メール送信は無し
          const keys = {
            mailAddress: this.register.mail,
          }
          console.log('keys',keys)
          createApiData(res.user.uid, keys);
        })
      }
    },
    pushEnter(key){
      console.log(key);
      if(key.key === "Enter"){
        this.submit();
      }
    }
  },
  created(){

  }
}
</script>